(<template>
  <section class="ticket-statistics">
    <div class="ticket-statistics__breadcrumbs__wrapper breadcrumbs">
      <router-link :to="{name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}"
                   class="breadcrumbs__link">{{ $gettext('Tickets') }}</router-link>
      <span class="breadcrumbs__arrow"></span>
      <button class="breadcrumbs__link">{{ $gettext('Tickets statistics') }}</button>
    </div>
    <div class="ticket-statistics__header">
      <h3 class="ticket-statistics__title">{{ $gettext('Tickets statistics') }}</h3>
      <button class="sk-btn sk-btn--white ticket-statistics__header-export-button"
              @click="downloadTicketsStatistics">{{ $gettext('Export to CSV') }}</button>
    </div>
    <page-data-section class="filter-bar">
      <monthly-range-picker v-for="(date, index) in datesList"
                            :key="index"
                            :description-from="date.descriptionFrom"
                            :description-to="date.descriptionTo"
                            :from="date.startDate"
                            :to="date.endDate"
                            class="filter-bar__field"
                            @startdatechanged="date.startDate.dateChanged"
                            @enddatechanged="date.endDate.dateChanged" />
    </page-data-section>
    <statistics-charts />
    <statistics-table />
  </section>
</template>)

<script>
  import {mapMutations, mapState} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import MonthlyRangePicker from '@/components/statistics/MonthlyRangePicker';
  import StatisticsTable from '@/pages/BuyerPages/ticket_system_pages/statistics/StatisticsTable';
  import StatisticsCharts from '@/pages/BuyerPages/ticket_system_pages/statistics/StatisticsCharts';

  export default {
    asyncData({store, route}) {
      const params = {
        page: route.query.page,
        items: route.query.items || store.state.filterItemsAmount || 10
      };
      let promisesArr = [];

      if (!store.state.TicketSystemStore.ticketsStatisticsLine.tickets) {
        promisesArr = [...promisesArr, store.dispatch('TicketSystemStore/fetchTicketsStatisticsLines', params)];
      }

      if (!store.state.TicketSystemStore.ticketsStatistics) {
        promisesArr = [...promisesArr, store.dispatch('TicketSystemStore/fetchTicketsStatistics')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'monthly-range-picker': MonthlyRangePicker,
      'page-data-section': PageDataSection,
      'statistics-charts': StatisticsCharts,
      'statistics-table': StatisticsTable,
    },
    computed: {
      ...mapState('TicketSystemStore', {
        startDate: (state) => state.filterStartDate,
        endDate: (state) => state.filterEndDate,
      }),
      datesList() {
        return {
          dateRange: {
            ...this.getRangeObject({
              descriptionFrom: this.dateFromText,
              descriptionTo: this.dateToText,
              startDate: this.startDate,
              endDate: this.endDate,
              mutationFunc: this.setDateRange
            })
          }
        };
      },
      dateFromText() { return this.$gettext('Date from'); },
      dateToText() { return this.$gettext('Date to'); },
    },
    watch: {
      startDate() {
        this.refetchData();
      },
      endDate() {
        this.refetchData();
      },
      $route() {
        this.refetchData();
      }
    },
    methods: {
      ...mapMutations('TicketSystemStore', ['setDateRange', 'clearTicketsStatistics', 'clearTicketsStatisticData']),
      downloadTicketsStatistics() {
        this.$store.dispatch('TicketSystemStore/exportTicketsStatistics').then((csvData) => {
          const blob = new Blob([csvData], {type: 'text/csv'});

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'tickets-statistics.csv';
          downloadLink.click();
        });
      },
      setStartDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = startDateMoment.format('YYYY-MM-DD');
        const endDate = startDateMoment.isBefore(endDateMoment)
          ? endDateMoment.format('YYYY-MM-DD')
          : startDateMoment.add(1, 'months').endOf('month').format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      setEndDate(startDateMoment, endDateMoment, mutationFunc) {
        const startDate = this.$moment(startDateMoment).format('YYYY-MM-DD');
        const endDate = endDateMoment.format('YYYY-MM-DD');
        mutationFunc({startDate, endDate});
      },
      getRangeObject({startDate, endDate, mutationFunc, descriptionFrom, descriptionTo}) {
        return {
          descriptionFrom,
          descriptionTo,
          startDate: {
            ...this.getDateObject(startDate),
            dateChanged: (startDateMoment) => {
              const endDateMoment = this.$moment(endDate, 'YYYY-MM-DD');
              this.setStartDate(startDateMoment, endDateMoment, mutationFunc);
            }
          },
          endDate: {
            ...this.getDateObject(endDate),
            dateChanged: (endDateMoment) => {
              const startDateMoment = this.$moment(startDate, 'YYYY-MM-DD');
              this.setEndDate(startDateMoment, endDateMoment, mutationFunc);
            }
          }
        };
      },
      getDateObject(date) {
        return {
          defaultMonth: this.$moment(date, 'YYYY-MM-DD').month() + 1,
          defaultYear: this.$moment(date, 'YYYY-MM-DD').year()
        };
      },
      refetchData() {
        this.clearTicketsStatistics();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      this.clearTicketsStatisticData();
      next();
    }
  };
</script>

<style scoped>
.ticket-statistics {
  flex-direction: column;
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding: 45px 0;
}

.breadcrumbs {
  display: flex;
  margin-bottom: 10px;
}

.breadcrumbs__arrow {
  margin: 0 4px;
  padding-right: 10px;
  background-image: url(~@assets/imgs/arrows/arrow_to_right_black.svg);
  background-position: 100% 50%;
  background-size: auto 10px;
  background-repeat: no-repeat;
}

.breadcrumbs__link:focus {
  color: var(--black-700);
  box-shadow: var(--default-focus-shadow);
  outline: none;
  font-size: 12px;
}

.ticket-statistics__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ticket-statistics__title {
  font-size: 24px;
  line-height: 32px;
}

.ticket-statistics__header-export-button {
  width: auto;
  padding-right: 15px;
  padding-left: 35px;
  background-image: url(~@assets/imgs/undefined_imgs/export_excel_doc_icon.svg);
  background-position: 7% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
  font-weight: 500;
}

.filter-bar {
  padding: 16px 20px;
  background-color: var(--col-white);
}

.filter-bar__field {
  width: calc(38% - 10px);
}

@media (max-width: 767px) {
  .ticket-statistics {
    padding: 10px;
  }
}
</style>
