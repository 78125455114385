// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/arrows/arrow_to_right_black.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/export_excel_doc_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.ticket-statistics[data-v-15ecf187] {\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  width: 1140px;\n  max-width: 100%;\n  margin: 0 auto;\n  padding: 45px 0;\n}\n.breadcrumbs[data-v-15ecf187] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 10px;\n}\n.breadcrumbs__arrow[data-v-15ecf187] {\n  margin: 0 4px;\n  padding-right: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 100% 50%;\n  background-size: auto 10px;\n  background-repeat: no-repeat;\n}\n.breadcrumbs__link[data-v-15ecf187]:focus {\n  color: var(--black-700);\n  -webkit-box-shadow: var(--default-focus-shadow);\n          box-shadow: var(--default-focus-shadow);\n  outline: none;\n  font-size: 12px;\n}\n.ticket-statistics__header[data-v-15ecf187] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 20px;\n}\n.ticket-statistics__title[data-v-15ecf187] {\n  font-size: 24px;\n  line-height: 32px;\n}\n.ticket-statistics__header-export-button[data-v-15ecf187] {\n  width: auto;\n  padding-right: 15px;\n  padding-left: 35px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 7% 50%;\n  background-size: 22px auto;\n  background-repeat: no-repeat;\n  font-weight: 500;\n}\n.filter-bar[data-v-15ecf187] {\n  padding: 16px 20px;\n  background-color: var(--col-white);\n}\n.filter-bar__field[data-v-15ecf187] {\n  width: calc(38% - 10px);\n}\n@media (max-width: 767px) {\n.ticket-statistics[data-v-15ecf187] {\n    padding: 10px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
